import React from 'react'

export const AmazonIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" width="44.621" height="44.619" viewBox="0 0 44.621 44.619">
      <defs>
        <linearGradient id="primary-fourth" x1="0" x2="1" y1="1" y2="0">
          <stop offset="0" stopColor="#262355" />
          <stop offset="1" stopColor="#5d5b7f" />
        </linearGradient>
      </defs>
      <path id="amazon-icon" fill="#primary-secondary" fillRule="evenodd" d="M0 0v44.62h44.62V0H0zm30.16 19.37c.21.3-.77 3.03-.979 2.89-.22-.14.2-1.81-.05-2.11-.24-.3-1.87-.16-1.96-.45-.101-.29 2.779-.631 2.989-.33zm-12.69-5.44c.16-.52.47-.96.83-1.37 1.26-1.02 2.77-1.29 4.33-1.46.43-.04 1.19.2 1.14-.73-.04-.83-.19-1.59-1.13-1.8-.91-.2-1.66.08-2.12.97-.32.62-2.43.9-2.78.33-.37-.6.05-1.19.34-1.68 1.58-2.75 6.5-2.4 7.88-.83.649.74.88 1.6.85 2.51-.05 1.64.12 3.28-.11 4.93-.08.56.2 1.34.74 1.74.48.36.2.55-.02.78-.4.43-.82.85-1.24 1.27-.49.34-.93.16-1.19-.28-.58-.99-1.13-.87-1.89-.17-.58.53-1.37.67-2.16.73-3.27.23-4.24-2.52-3.47-4.94zm11.26 6.46c-2.07 3.4-9.25 3.14-13.82-.39 4.49 2.01 9.04 2.45 13.82.39zM16.72 37.8H6.76v-2.141h9.96V37.8zm0-2.79H6.76v-2.141h9.96v2.141zm0-2.79H6.76v-2.141h9.96v2.141zm0-2.79H6.76v-2.14h9.96v2.14zm10.6 8.37h-9.97v-2.141h9.97V37.8zm0-2.79h-9.97v-2.141h9.97v2.141zm10.62 2.79h-9.97v-2.141h9.97V37.8zm0-2.79h-9.97v-2.141h9.97v2.141zm0-2.79h-9.97v-2.141h9.97v2.141zm-16.21-15.6c.87.03 1.35-.5 1.69-1.22.239-.49.39-1 .319-1.64-.13-.28.37-.98-.479-.97-.74 0-1.49.13-2.1.61-.75.59-.8 1.44-.61 2.3.14.61.59.889 1.18.92z" clipRule="evenodd" />
    </svg>
  )
}